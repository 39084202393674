import React, { useEffect, useState } from 'react';
import './otpSection.scss';
import { connect } from 'react-redux';
import { updateUserData, verifyOTP } from '../../redux/action/user'
import TextBox from '../../atoms/textbox';
import ButtonInput from '../../atoms/button';
import { useNavigate } from "react-router-dom";
import LabelInput from '../../atoms/label';
import SnackBar from '../../atoms/snackBar';
import backLeftImage from '../../assets/images/back-left.png';
import { parsePhoneNumber } from 'react-phone-number-input';

const OtpSection = (props) => {
    const navigate = useNavigate();
    const [enableButton, setEnableButton] = useState(true);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (props.user?.otpError) {
            setErrorMessage(props.user?.otpError)
        }
    }, [props.user?.otpError]);

    useEffect(() => {
        props.updateUserData({
            ...props.user,
            otpError: "",
            otp: ''
        });
        
        setErrorMessage('')
    }, [])

    const redirectToPage = (page) => {
        if (props.changePage)
            props.changePage(page)
    }
    
    const redirectBack = () => {
        /* props.updateUserData({
            ...props.user,
            status: props.isLoginPage ? 'login' : 'signup',
        }); */

        redirectToPage(props.isLoginPage ? 'login' : 'signup')

        setTimeout(() => {
            props.updateUserData({
                ...props.user,
                registrationError: "",
                loginError: "",
                status: props.isLoginPage ? 'login' : 'signup',
            });
        }, 1500);
    }

    useEffect(() => {
        if (props.user?.registrationError) {
            setErrorMessage(props.user?.registrationError)
            redirectBack();
        }
    }, [props.user?.registrationError])

    useEffect(() => {
        if (props.user?.loginError) {
            setErrorMessage(props.user?.loginError)
            redirectBack()
        }
    }, [props.user?.loginError])

    useEffect(() => {
        if (errorMessage) {
            setOpenSnackBar(true);
        }
    }, [errorMessage])

    const handleCloseSnackBar = () => {
        setOpenSnackBar(false);
        setErrorMessage("");
    }

    const otpChange = (value) => {
        if (value.length > 6) {
            return;
        }

        props.updateUserData({
            ...props.user,
            otp: value
        })

        if (value.length === 6) {
            setEnableButton(false);
        } else {
            setEnableButton(true);
        }
    }

    const goBack = () => {
        /* props.updateUserData({
            ...props.user,
            status: 'login',
            // isNewUser: false,
        }) */

        redirectToPage(props.isLoginPage ? 'login' : 'signup')
    }

    const verfiyClick = () => {
        const tempObj = {
            hash: props.user.hash,
            otp: props.user.otp,
            isLogin: props.isLoginPage
        }
        
        if (props.isLoginPage){
            const phone = parsePhoneNumber(props?.user?.loginForm?.mobileNumber)
            const phoneNumber= removeCountryCode({code: '+' + (phone?.countryCallingCode ?? ''), phone: props?.user?.loginForm?.mobileNumber})

            tempObj['emailaddress'] = props.user?.loginForm?.emailaddress;
            tempObj['countryCode'] = '+' + (phone?.countryCallingCode ?? '');
            tempObj['mobileNo'] = phoneNumber
        } else {
            const phone = parsePhoneNumber(props?.user?.signupForm?.mobileNumber)
            const phoneNumber= removeCountryCode({code: '+' + phone.countryCallingCode, phone: props?.user?.signupForm?.mobileNumber})
            
            tempObj['emailaddress'] = props.user?.signupForm?.emailaddress;
            tempObj['countryCode'] = '+' + phone.countryCallingCode;
            tempObj['mobileNo'] = phoneNumber
        }
        // console.log(props)
        props.verifyOTP(tempObj, navigate);
    }
    const removeCountryCode= (data)=> {
        // console.log(data)
        var newStr = data.phone.replace(data.code,'')
        return newStr
    }

    useEffect(() => {
        if (props.user?.otpSuccess) {
            if (localStorage.getItem('progressDone') === null) {
                navigate('/progress');
            } else if (localStorage.getItem("detailDone") === null) {
                navigate('/profiledetail');
            } else {
                navigate('/result');
            }
        }
    }, [props.user?.otpSuccess])

    return (
        <div className="login_form position-relative">
            <div onClick={() => goBack()} className='back-left-wrap cursor-pointer'>
                {/* <img src={backLeftImage} alt={backLeftImage} ></img> */}
                Back
            </div>
            <div className="container-fluid">
                <div className="row">
                    <h2 className='white-color avenir-bold'>{props.user.language?.otp?.title}</h2>
                    <p className='subTitle'>
                        {props.user.language?.otp?.subTitle}
                    </p>
                    <p className='subTitle1'>
                        {props.user.language?.otp?.subTitle1}
                    </p>
                </div>
                <LabelInput text={props.user.language?.otp?.otpField?.title} />
                <TextBox
                    className='otpTextBox'
                    placeholder={props.user.language?.otp?.otpField?.placeHolder}
                    value={props.user?.otp}
                    valueChange={otpChange} />

                <div className="row">
                    <ButtonInput
                        class={`assignment-btn ${enableButton ? 'deactive-btn' : 'active-btn'}`}
                        text={props.user.isNewUser ? props.user.language?.otp?.beingAssessmentButtonText : props.user.language?.otp?.submitButtonText}
                        onSubmit={verfiyClick}
                        disabled={enableButton} />
                </div>
                <div className="row text-right">
                    <p className='white bottom-section'>
                        {props.user.language?.support?.text}
                        <span className="yellow-font" style={{ marginLeft: '5px' }}>
                            <a className='yellow-font sign-up-welcome' href={`mailto: ${props.user.language?.support?.email}`}>
                                {props.user.language?.support?.email}
                            </a>
                        </span>
                    </p>
                </div>
            </div>
            <SnackBar open={openSnackBar} message={errorMessage} handleCloseSnackBar={handleCloseSnackBar} />
        </div>
    );
};

OtpSection.propTypes = {};
OtpSection.defaultProps = {};

const actionCreators = {
    updateUserData,
    verifyOTP,
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(OtpSection);